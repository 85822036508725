const en = {
    navigationLinks: {
        myFeed: 'My Specialty',
        otherQuestions: 'Other Specialties',
        topics: 'Questions',
        publicQuestions: 'Public Questions',
        allQuestions: 'All Questions',
        users: 'Users',
        logoutButton: 'Log out',
        yourProfile: 'Your profile',
        comingSoon: 'Coming soon!',
        programs: 'Programs',
        programOverview: 'Program overview',
        createEmails: 'Create emails',
        resources: 'Resources',
        submitConsensus: 'Submit a Summary',
        caseConsensuses: 'Case Consensuses',
        popup: {
            moreQuestionsLabel:
                'There are more questions related to your specialty, be sure not to miss them!',
            moreQuestionsButton: 'Answer More Questions',
            dontShowPopupAgain: "Don't show this again",
        },
        search: 'Search',
        home: 'Home',
        submitPatientCase: 'Submit Patient Case',
    },
    notFoundPage: {
        message:
            "You've been redirected here because the page you requested doesn't exist or you don't have permission for it.",
    },
    questionRewardBadge: {
        rewardEarned: 'Reward earned',
        rewardAvailable: 'Reward available',
        rewardBonus: '$25 Bonus',
    },
    common: {
        validationErrorMessages: {
            required: 'Required',
            emailFormat: 'Email address is in invalid format.',
            npiFormat: 'NPI should be a number',
            npiInvalid: 'This NPI does not match the NPI standard',
            npiLengthInvalid: 'NPI should be 10 digits long',
            npiMaxLength: 'Maximum characters NPI field support is 18',
        },
        save: 'Save',
        cancel: 'Cancel',
        delete: 'Delete',
        edit: 'Edit',
        yes: 'Yes',
        no: 'No',
        back: 'Back',
        warning: 'Warning',
        datePickerButton: 'Pick a date',
        fieldNames: {
            id: 'ID',
            label: 'Label',
            description: 'Description',
            question: 'Question',
            title: 'Title',
            specialty: 'Specialty',
            subSpecialty: 'Subspecialty',
            name: 'Name',
            phoneNumbers: 'Phone numbers',
            gender: 'Gender',
            practiceType: 'Practice type',
            useNpiMap: 'Use NPI map',
            npiMap: 'NPI map',
            therapyArea: 'Therapy Area',
            primaryIndication: 'Primary Indication',
            secondaryIndication: 'Secondary Indication',
            stageProgression: 'Stage/Progression',
            initiatingTeam: 'Initiating Team',
        },
        topicStatuses: {
            active: 'Active',
            expired: 'Expired',
            scheduled: 'Scheduled',
        },
        agree: 'I agree',
        notAvailable: 'N/A',
        initiatingTeamOptions: [
            { key: 1, value: 'MS', text: 'MS' },
            { key: 2, value: 'IQ', text: 'IQ' },
            { key: 3, value: 'MDRX', text: 'MDRX' },
            { key: 4, value: 'MDIA', text: 'MDIA' },
            { key: 5, value: 'HCP', text: 'HCP' },
        ],
    },
    topic: {
        postAnswer: 'Post answer',
        postComment: 'Post comment',
        commented: 'Commented',
        answered: 'Answered',
        edited: 'Edited',
        agree: 'Agree',
        disagree: 'Disagree',
        comment: 'Comment',
        deleteAnswer: 'Are you sure you want to delete this answer?',
        deleteComment: 'Are you sure you want to delete this comment?',
        deleteConfirmation: 'Confirmation Dialog',
        deletedComment: 'This comment was deleted',
        deletedAnswer: 'This answer was deleted',
        deletedAnswerOwn: 'Your answer was deleted',
        sortButton: 'Order Posts',
        orderDate: 'Date',
        orderRating: 'Rating',
        replyPlaceholder: 'Write your response here...',
        responsesHeader: 'Responses from other professionals.',
        answerBoxLabel: 'Your contribution',
        commentPlaceholder:
            'Suggestion: Use comments to ask for more information or suggest improvements. Avoid comments like “agree” or “thanks.”',
        noResponses:
            'No responses from other professionals yet. Be the first one to answer.',
        yourResponseOnly: 'No responses from other professionals yet.',
        anonymityClaim: 'This action is anonymous to other users.',
        expires: 'Expires',
        flagQuestion: {
            submitButton: 'Submit',
            userEmailLabel: 'User email',
            titleLabel: 'Title',
            messageLabel: 'Message',
            suggestMessage:
                "I'd like to report inappropriate content on this question",
            successMessage:
                'Thank you for letting us know. Our support got your message and will get back to you.',
            errorMessage:
                'Sorry for inconvenience. Please try again. If this problem persists consider sending email to support@healthcasts.com.',
            flagQuestion: 'Report',
            report: '[Report]',
            successTitle: 'Question Reported Successfully',
            errorTitle: "Sorry, can't report right now",
        },
        topicExpired:
            'This question expired on |. For more information on what an expired question is, click here: |.',
        faq: 'FAQ Page',
        viewResponses: 'Click here to see how others responded to this question',
        viewResponsesShort: 'Interested how others responded to this question?',
        zeroResponses: 'None answered the question in active period',
        nextQuestion: 'Next question:',
    },
    thread: {
        expiredHeader: 'Question Archive',
        authorPrefix: 'by',
        expired: 'Expired',
        expires: 'Expires',
        begins: 'Begins',
        noQuestions: {
            noQuestionsMessage:
                "You're all caught up! Check back soon or explore other questions.",
            noQuestionsMessageOther: "You're all caught up! Check back soon.",
            noQuestionsMessageAdmin: 'There are currently no active questions',
            viewOtherQuestions: 'View Other Questions',
            viewAll: 'View all',
        },
        programNotice: {
            thank: 'Thank You!',
            answeredQuestionText:
                'Your answer was successfully submitted. Before you continue, please view some sponsored educational content to help keep Healthcasts free for all physicians. You can exit the program at any time.',
            showAnswersText:
                'Before you view the responses from your colleagues, please view some sponsored educational content to help keep Healthcasts free for all physicians. You can exit the program at any time.',
            button: 'Next',
            nextQuestionText:
                'Thank You! Before your view the next question, please view some sponsored educational content to help keep Healthcasts free for all physicians. You can exit the program at any time.',
        },
        actionButtonsContent: {
            viewQuestion: 'View Question',
            viewAnswer: 'View Your Contribution',
        },
    },
    supportPage: {
        yourEmail: 'Your Email',
        yourFirstName: 'First Name',
        yourLastName: 'Last Name',
        contactRequest: 'Contact Request',
        message: 'Message',
        formTitle: "Get in touch. We'd love to hear from you.",
        contactUsButton: 'Contact us',
        submitContactRequest: 'Contact support',
        subject: 'Title',
        question: 'Question',
        sendingEmailSuccess: 'Email sent to support successfully!',
        sendingEmailError: 'Error while sending email!',
    },
    my: {
        profile: {
            myProfileHeading: 'My Profile',
            aboutMe: {
                caption: 'About me',
                labels: {
                    specialty: 'Specialty',
                    emailAddress: 'Email address',
                    npiNumber: 'NPI number',
                },
            },
            settings: {
                caption: 'My Settings',
                labels: {
                    showTooltips: 'Show tooltips?',
                },
            },
        },
        rewards: {
            myRewardsHeading: 'My Rewards',
            welcomeRewardsHeading: 'Welcome to your Rewards section!',
            welcomeRewardsContent: {
                main: "Here you can find your current reward eligibility and a history of the rewards you've earned on Healthcasts. Note that each new reward eligibility period begins on Sunday at 12am UTC (Saturday 7pm EST, 4pm PST). If you need any assistance, please ",
                contactMemberService: 'contact Member Services',
            },
            amazonGiftCard: 'Amazon credit',
            answeredQuestionsAddon: 'answered question',
            rewardsHistory: 'Rewards history',
        },
    },
    tooltips: {
        questionsAvailableRewardsIcon:
            'The number and amount or rewards available for to you for your answers are displayed here. Your rewards are refreshed every Sunday so check back often!',
        questionsGiftCardIcon:
            'You are eligible for rewards flagged with the "Reward available" icon. Choose a question to earn a reward!',
        questionGiftCardIcon:
            'The "Rewards available" icon appears on questions where you can earn a reward by providing an answer. Simply answer a question to earn your reward.',
        questionAfterUserEarned:
            'The "Reward Earned" icon appears on questions where you have earned a reward. Rewards are sent via email in the form of gift card codes.',
        pointToMyRewards:
            'You can keep track of your earned rewards in your "My Rewards" section of Healthcasts. Click here and select "My Rewards" to view your "My Rewards" section.',
        rewardsHistory:
            'Your "Rewards History" section displays a history of the rewards you have earned on Healthcasts. Rewards are sent via email to the email address associated with your account. You can view this email address in the "My Profile" section.',
        finishRewards:
            'You can view more questions and answers from your colleagues or check your available rewards by clicking "My Specialty." Thank you for participating with Healthcasts!',
    },
    admin: {
        users: {
            form: {
                emailUniqueError: 'User with that email already exists.',
                npiUniqueError:
                    'User with that number already exists. Will auto-generate a new number in 5 seconds, please try submitting again.',
            },
        },
        questions: {
            questionSearch: {
                keywords: 'Keywords',
                specialty: 'Specialty',
                filterResults: 'Filter results',
                scheduled: 'Scheduled',
                active: 'Active',
                expired: 'Expired',
                searchReset: 'Reset Search',
                keywordsHolder: 'Type something',
                specialtyHolder: 'Select specialty',
            },
            questionForm: {
                author: 'Author',
                createQuestion: 'Create Question',
                updateQuestion: 'Update Question',
                expirationDate: 'Expiration date',
                publishDate: 'Publish date',
                question: 'Question',
                questionModeration: 'All Question Moderation',
                publishDateValidation:
                    'Publish date must come before the expiration date',
                specsValidation: 'At least one specialty or subspecialty required',
                datesValidation: 'Selected date is in the past',
                results: 'Results',
                createTitle: 'New question',
                editTitle: 'Edit question',
                unspecifiedSelection: 'Not selected',
                hideAnswers: 'Hide answers for user',
                questionsNpis: 'NPIS',
            },
            list: {
                authorPrefix: 'by',
                downloadTarget: 'Download target list',
                editQuestion: 'Edit question',
                editQuestionSuccess: 'Question edited successfully!',
                createQuestionSuccess: 'Question created successfully!',
                deleteQuestion: 'Delete question',
                deleteQuestionSuccess: 'Deleted question successfully',
                deleteQuestionFailure: 'Deleting question failed',
                downloadError: 'Error downloading target list!',
            },
            details: {
                deleteEmail: 'Delete Email',
                pageTitle: 'Back to All Questions',
                userEngagementTitle: 'User Engagement',
                emailListTitle: 'Email List',
                answers: 'Answers',

                totalResponses: 'Total responses',
                uniqueViews: 'Unique views',
                targetList: 'Target size',
                eligibleList: 'Eligible users',
                email: 'Emails',
                sumRewards: 'Reward Sum',
                sumPeriodRewards: 'Reward Period Sum',
                answerReport: 'Answer Report',
                downloadReportError: 'Error downloading answer report!',
                answerReportLink: 'Answer report •',
                targetSpecialties: 'Target Specialties',
                targetAudience: 'Target Audience',
                userCount: 'User Count',
                newEmail: 'New Email',
                deleteQuestionModal: 'Are you sure you want to delete this question?',
                tableHeaders: {
                    createdAt: 'Created at',
                    emailName: 'Email name',
                    emailId: 'Email ID',
                    actions: 'Actions',
                    programId: 'ID',
                    programName: 'Name',
                    programAirDate: 'Air Date',
                    priority: 'Priority',
                },
                connectedPrograms: 'Connected Programs',
                attachProgram: 'Attach Program',
                detachAll: 'Detach all',
                detachWarning:
                    'You are about to detach the selected program from this question. Are you sure?',
                detachAllWarning:
                    'You are about to detach all programs from this question. Are you sure?',
                modalHeader: 'Attach Programs to this Question',
                loadingModalPrograms: 'Loading programs',
                programsCountText: 'Programs selected:',
                attach: 'Attach Selected',
                detach: 'Yes, detach this program',
                attachError: 'Error attaching programs!',
                detachError: 'Error detaching programs!',
                priorityError: 'Error changing priority!',
                waitCompletion:
                    'Target list is in process of creation. Please come back later and reload the page!',
            },
            newCampaignForm: {
                all: 'All',
                viewed: 'Viewed the question',
                notViewed: "Didn't view the question",
                notResponded: "Didn't respond to question",
                responded: 'Responded to question',
                eligible: 'Program eligible users',
                title: 'New Question Email',
                targetAudience: 'Target audience',
                emailName: 'Email name',
                selectTemplatePlaceholder: 'Select template...',
                createEmail: 'Create Email',
                createEmailFailed: 'Error creating new email!',
            },
            campaignDeleteModal: {
                header: 'Delete Email',
                content: 'Are You sure You want to delete this email?',
            },
            emptyAllProgramsState: 'There are currently no active programs.',
            emptyProgramsState:
                'You can use this area to associate programs with this question. Click "Attach a Program" above to attach specific programs to this question.',
            emptyEmailsState:
                'There are currently no emails created for this question. Click the New Email button to do this.',
            npis: {
                heading: "Mapping of questions to NPI's",
                description: 'Please paste CSV in format "Question ID, npi"',
                validations: {
                    invalidRow: number =>
                        `Row ${number} have invalid format, should by "number,number"`,
                },
            },
        },
        globalHcmpList: {
            programsOverview: 'Programs Overview',
            errorText: 'Error while executing action!',
            programsWithoutPriority: 'Programs without priority set',
            assignPriority: 'Assign priority',
            confirm: 'Confirm',
            disable: 'Disable',
            yes: 'YES',
            no: 'NO',
            viewDetails: 'View Details',
            disableProgram: 'Disable Program',
            enableProgram: 'Enable Program',
            specialties: 'Specialties',
            programGoalProgress: 'Program goal progress',
            tableHeaders: {
                id: 'ID',
                name: 'Name',
                enabled: 'Enabled',
                priority: 'Priority',
                actions: 'Actions',
            },
            programs: {
                createEmails: {
                    createEmails: 'Create emails',
                    createEmail: 'Create email',
                    deleteEmail: 'Delete Email',
                    emailListTitle: 'Email List',
                    email: 'Emails',
                    targetSpecialties: 'Target Specialties',
                    targetAudience: 'Target audience',
                    userCount: 'User Count',
                    newEmail: 'New Email',
                    tableHeaders: {
                        createdAt: 'Created at',
                        emailName: 'Email name',
                        emailId: 'Email ID',
                        actions: 'Actions',
                    },
                    waitCompletion:
                        'Target list is in process of creation. Please come back later and reload the page!',
                    selectAll: 'Select all',
                    formatTitle: 'Format: ',
                    formatDescription: 'NPI,QuestionID (optional), e.g.: 74578414,12',
                    npiPlaceholder: 'Paste here to generate CSV file',
                    newSpecialtyCampaignForm: {
                        title: 'New specialty specific email',
                        specialtyAll: 'All',
                        specialtyEligible: 'Program eligible users',
                        npi: 'Selected by NPI',
                        targetAudience: 'Target audience',
                        emailName: 'Email name',
                        selectTemplatePlaceholder: 'Select template...',
                        createEmail: 'Create Email',
                    },
                    newNpiCampaignForm: {
                        title: 'New NPI email',
                        emailName: 'Email name',
                        selectTemplatePlaceholder: 'Select template...',
                        createEmail: 'Create Email',
                    },
                    campaignDeleteModal: {
                        header: 'Delete Email',
                        content: 'Are You sure You want to delete this email?',
                    },
                    emptyEmailsState: 'There are currently no emails created.',
                    createEmailFailed: 'Error creating new email!',
                },
            },
        },
    },
    roles: {
        initial: 'Initial',
        moderator: 'Moderator',
        admin: 'Administrator',
        salesRep: 'Sales Representative',
        iqModerator: 'IQ Moderator',
        searchRole: 'Search moderator',
    },
    disclaimer: {
        title: 'Disclaimer',
        privacyPolicy: 'Privacy policy',
        /* eslint-disable */
        text: `By using "Clinical Consult Platform", you acknowledge and agree that "Clinical Consult Platform" is provided solely for educational purposes and the information made available to you through "Clinical Consult Platform" may not be used for treatment or any other purposes. In addition, information collected from your participation in “Clinical Consult Platform” may be shared with third parties. You further agree that you will not provide any information through "Clinical Consult Platform " that is based upon, associated with, or may be used to identify an individual/patient or that you do not otherwise have the right and authority to provide.`,
        /* eslint-enable */
    },
    about: {
        title: 'About Page',
        description1: 'Asked by Physician, Answered by Physicians',
        description2:
            'Healthcasts is a physician-to-physician knowledge sharing network to improve clinical decisions and patient outcomes.',
        header1: 'Our Mission',
        content1:
            'To inspire and empower informed patient-care decisions by sharing knowledge from the world’s most trusted experts.',
        header2: 'Credo',
        content2:
            'Our primary commitment is to the healthcare professionals who are on the frontline of patient care worldwide — we serve these professionals in their efforts to drive superior patient outcomes. We are committed to our employees who dedicate their efforts to ensuring our physician members, their patients, and our clients achieve success in their health objectives.',
        footer: 'For more information, visit our | page',
        faq: 'FAQ',
    },
    faq: {
        title: 'FAQ',
        questions: [
            {
                q: 'What is Healthcasts?',
                a: 'Healthcasts is a physician-to-physician knowledge sharing network to improve clinical decisions and patient outcomes.',
            },
            {
                q: 'How often are questions posted?',
                a: 'Questions are currently posted once a week.',
            },
            {
                q: 'Can I ask a question?',
                a: 'Currently, questions on Healthcasts can only be submitted by members of our physician content advisory board.',
            },
            {
                q: 'Are my answers public?',
                a: 'Yes. All answers submitted on Healthcasts are available to our verified physician members.',
            },
            {
                q: 'Can other physicians see if I "agree" or "disagree" with their answer?',
                a: 'No, physician members cannot see who agrees or disagrees with their answer. Physician members can only see the number of "agree" or "disagree" ratings to their answers.',
            },
            {
                q: 'Is every member of Healthcasts a verified physician?',
                a: 'Yes.',
            },
            {
                q: 'Where is Healthcasts located?',
                a: 'Healthcasts offices are located in mid-town Manhattan, New York, NY.',
            },
        ],
    },
    general: {
        closePastMoment: 'a few moments ago',
    },
};

export const stringTemplates = {
    validations: {
        stringLengthMin: (min, property = 'Text') =>
            `${property} must be at least ${min} characters long.`,
        stringLengthMax: (max, property = 'Text') =>
            `${property} must be at most ${max} characters long.`,
    },
    warnings: {
        stringCharactersLeft: num => `${num} characters left.`,
    },
};

export default en;
